import * as React from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import './index.css';

const VenuePage = () => (
  <Layout>
    <Seo
      title="Menu"
      description="Here is where you will find all the information on the food and drink
          options."
    />
    <div className="content">
      <div className="HomeText">
        <h1>The Menu</h1>
        {/* <p>
          All guests are now asked to provide their late lunch order from the
          menu below. Please include any additional dietary requirements with
          your order. Send your choice to us via any messaging service (such as
          Whatsapp, Messenger or text).
        </p>

        <hr style={{ marginTop: '2rem' }} />
        <h2>DRINKS RECEPTION</h2> */}
        <p>
          Following the ceremony, guests will be led into the Wingfield Lounge
          for a complimentary bottle of beer or glass of prosecco. Charcuterie
          boards will be serving nibbles and the bar will be open for any
          additional drinks.
        </p>
        <hr />
        <h2>LATE LUNCH: PUB GRUB</h2>
        <p>
          This meal will be served to the tables following a prearranged seating
          plan. Please find your allocated seat and get ready to tuck in.
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>Main Meal</span>
        </p>
        <div className="MenuList">
          <h3>Chicken Roast Dinner</h3>
          <ul>
            <li>
              Served with homemade yorkshire puddings, roast potatoes, seasonal
              vegetables and gravy.
            </li>
          </ul>
          <h3>Vegetarian Roast Dinner</h3>
          <ul>
            <li>
              Veggie sausages (gf) served with yorkshire puddings, roast
              potatoes, seasonal vegetables and gravy.
            </li>
          </ul>
          <h3>Steak and Ale Pie</h3>
          <ul>
            <li>
              Served with creamed mash potatoes, seasonal vegetables and peas.
            </li>
            <li>
              Please choose between mushy{' '}
              <span style={{ fontWeight: 600 }}>or</span> garden peas.
            </li>
          </ul>
        </div>
        <div style={{ padding: 12 }} />
        <p>
          <span style={{ fontWeight: 600 }}>Dessert</span>
        </p>
        <div className="TwoColList">
          <h3>Passion Fruit Brulee</h3>
          <ul>
            <li>Served with shortbread heart and lemon macaroon.</li>
          </ul>
          <h3>Sticky Toffee Pudding</h3>
          <ul>
            <li>Served with sticky toffee sauce & ice cream.</li>
          </ul>
        </div>
        {/* <div style={{ padding: 20 }} /> */}
        {/* <div
          style={{ height: '1px', width: '100%', borderTop: 'dashed 2px #000' }}
        /> */}
        <div style={{ padding: 12 }} />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '2rem',
          }}
        >
          <div>
            <p>
              <span style={{ fontWeight: 600 }}>Vegan Set Menu:</span>
            </p>
            <div>
              <h3>Cauliflower, Aubergine & Lentil Curry</h3>
              <ul>
                <li>Served with basmati rice & a grilled tortilla.</li>
              </ul>
              <br />
              <h3>Warm Spiced Apple and Carrot Slice</h3>
              <ul>
                <li>Iced with warm maple syrup.</li>
              </ul>
            </div>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: 600 }}>Children's Set Menu:</span>
            </p>
            <div>
              <h3>Sausage and mash</h3>
              <ul>
                <li>Served with garden peas, seasonal vegetables and gravy.</li>
              </ul>
              <br />
              <h3>Fruit & Marshmallows</h3>
              <ul>
                <li>Served with chocolate dip.</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ padding: 10 }} />
        <hr />
        <h2>EVENING MEAL: WOOD-FIRED PIZZA</h2>
        <p>
          We do not require a choice of pizza, as all pizzas are freshly made
          and served directly to you out of the wood-fired oven on the night.
          For our gluten-free / dairy free guests, please state your dietary
          preference upon arrival to the pizza station. A takeaway box is
          provided to keep any leftovers safe.
        </p>
        <div className="TwoColList">
          <h3>Farmhouse</h3>
          <ul>
            <li>Grilled chicken, ham, spinach, mozzarella.</li>
          </ul>
          <h3>From The Roots</h3>
          <ul>
            <li>Roasted beetroot, butternut, caramelised onion, feta.</li>
          </ul>
        </div>
        <div style={{ padding: 10 }} />
      </div>
    </div>
  </Layout>
);

export default VenuePage;
